.App {
  text-align: center;
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
  background: radial-gradient(#ffeb2c, #c55c22)

}


